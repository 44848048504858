import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Layout = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false); // Stav pre zobrazenie košíka
  const cartItems = useSelector((state) => state.cart.items); // Položky z Redux store
  const totalPrice = useSelector((state) => state.cart.totalPrice); // Celková cena

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 relative">
      {/* Sticky Navigation */}
      <header className="bg-white shadow-md sticky top-0 z-50">
        <nav className="container mx-auto p-4 flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-gray-800"><img 
            src={`${process.env.PUBLIC_URL}/images/logo/logo.jpg`} 
            alt="logo" 
            style={{ width: '150px', height: 'auto' }} 
          />
          </Link>
          <div className="hidden md:flex space-x-6">
            <Link to="/" className="text-gray-600 hover:text-gray-900">Home</Link>
            <Link to="/about" className="text-gray-600 hover:text-gray-900">About</Link>
            <Link to="/blog" className="text-gray-600 hover:text-gray-900">Blog</Link>
          </div>
          <div className="flex space-x-4 items-center">
            <button onClick={toggleCart} className="relative">
              <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/cart.svg" className="w-8 h-8" alt="Cart" />
              {cartItems.length > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white rounded-full text-xs px-2 py-1">
                  {cartItems.length}
                </span>
              )}
            </button>
          </div>
        </nav>
      </header>

      {/* Overlay pre zobrazenie košíka */}
      {isCartOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity"
          onClick={toggleCart} 
        ></div>
      )}

      {/* Cart Drawer */}
      <div
        className={`fixed top-0 right-0 h-full w-80 bg-white shadow-lg z-50 transition-transform transform ${isCartOpen ? 'translate-x-0' : 'translate-x-full'}`}
        style={{ transition: 'transform 0.3s ease-in-out' }}
      >
        <div className="p-4 flex justify-between items-center border-b">
          <h3 className="text-lg font-semibold">Your Cart</h3>
          <button onClick={toggleCart} className="text-gray-600 hover:text-gray-900">&times;</button>
        </div>

        <div className="p-4 overflow-y-auto flex-grow">
          {cartItems.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <ul className="mb-4 space-y-4">
              {cartItems.map((item) => (
                <li key={item.id} className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold">{item.name}</p>
                    <p className="text-gray-600 text-sm">{item.quantity} x ${item.price}</p>
                  </div>
                  <p className="font-semibold">${item.totalPrice.toFixed(2)}</p>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="p-4 border-t">
          <p className="font-semibold text-lg">Total: ${totalPrice.toFixed(2)}</p>
          <Link to="/cart" className="block mt-4 bg-indigo-600 text-white text-center py-2 rounded-full">
            View Cart
          </Link>
        </div>
      </div>

      {/* Content Area */}
      <main className="flex-grow">
        {children}
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center md:items-start space-y-4 md:space-y-0">
          <div className="text-center md:text-left">
            <h3 className="text-lg font-semibold">Annabeth</h3>
            <p className="text-gray-400">The finest selection of premium jewelry.</p>
          </div>
          <div className="flex space-x-4">
            <Link to="/privacy" className="text-gray-400 hover:text-white">Privacy Policy</Link>
            <Link to="/terms" className="text-gray-400 hover:text-white">Terms of Service</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
