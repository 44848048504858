import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux'; // Import Redux Provider
import store from './store/store'; // Import Redux store
import Home from './components/Home'; // Import Home komponentu
import Cart from './components/Cart';
import About from './components/About';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Checkout from './components/Checkout';
import Detail from './components/Detail'; // Import Detail komponentu
import Blog from './components/Blog';
import Article from './components/Article';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Provider store={store}>
      <Router>
      <ScrollToTop /> {/* Pridajte ScrollToTop komponent */}
        <div className="App">
          {/* Definovanie routes */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/about" element={<About />} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/product/:id" element={<Detail />} /> {/* Pridaná cesta pre Detail produktu */}
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
