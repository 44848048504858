import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Import Redux useDispatch
import { addItemToCart } from '../store/Cart'; // Import Redux akcie
import Layout from './Layout'; // Import Layout komponentu
import ImageGallery from 'react-image-gallery'; // Import ImageGallery z knižnice
import 'react-image-gallery/styles/css/image-gallery.css'; // Štýly pre galériu

const pb = new PocketBase('https://annabeth.store'); // Inicializácia PocketBase

const Detail = () => {
  const { id } = useParams(); // Získanie ID produktu z URL
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]); // Stav pre obrázky produktu
  const dispatch = useDispatch(); // Definovanie dispatchu pre Redux

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const record = await pb.collection('products').getOne(id);
        setProduct(record);

        // Priprav obrázky pre galériu
        if (record.images && record.images.length > 0) {
          const galleryImages = record.images.map((img) => ({
            original: pb.files.getUrl(record, img),
            thumbnail: pb.files.getUrl(record, img, { thumb: '100x100' }),
          }));
          setImages(galleryImages);
        }
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleAddToCart = () => {
    if (product) {
      dispatch(
        addItemToCart({
          id: product.id,
          name: product.name,
          price: product.price,
        })
      );
    }
  };

  if (!product) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto py-12 px-4 md:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="max-w-full">
            {/* Galéria obrázkov */}
            <ImageGallery items={images} showPlayButton={false} />
          </div>
          <div>
            <h1 className="text-4xl font-bold text-gray-900 mb-6">{product.name}</h1>
            <p className="text-gray-600 text-lg leading-relaxed mb-6">{product.description}</p>
            <p className="text-3xl font-semibold text-gray-800 mb-8">${product.price}</p>
            <button
              onClick={handleAddToCart}
              className="w-full md:w-auto bg-indigo-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-indigo-700 transition-colors"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Detail;
