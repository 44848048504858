import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';
import Layout from './Layout'; // Import Layout komponentu
import { useDispatch } from 'react-redux'; // Import Redux useDispatch
import { addItemToCart } from '../store/Cart'; // Import addItemToCart akcie
import scrollToSection from './ScrollToSection';

const pb = new PocketBase('https://annabeth.store'); // Inicializácia PocketBase

const Home = () => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState('');
  const [sortBy, setSortBy] = useState('-created');
  const [minPrice, setMinPrice] = useState(''); // Cena od
  const [maxPrice, setMaxPrice] = useState(''); // Cena do

  const dispatch = useDispatch(); // Definovanie dispatchu pre Redux

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Zostavenie filtra na základe kategórie a ceny
        let filter = category ? `category="${category}"` : '';
        if (minPrice) {
          filter += filter ? ` && price >= ${minPrice}` : `price >= ${minPrice}`;
        }
        if (maxPrice) {
          filter += filter ? ` && price <= ${maxPrice}` : `price <= ${maxPrice}`;
        }

        const result = await pb.collection('products').getList(1, 25, {
          sort: sortBy,
          filter: filter,
        });
        setProducts(result.items);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [category, sortBy, minPrice, maxPrice]);

  const handleAddToCart = (product) => {
    dispatch(addItemToCart({
      id: product.id,
      name: product.name,
      price: product.price,
    }));
  };

  const getImageUrl = (product) => {
    if (product.images && product.images.length > 0) {
      return pb.files.getUrl(product, product.images[0], { thumb: '300x300' });
    }
    return 'https://via.placeholder.com/300'; // Náhradný obrázok, ak produkt nemá obrázok
  };

  return (
    <Layout>
      {/* Hero Section */}
      <section 
        className="bg-cover bg-center text-white h-96 flex items-center justify-center"
        style={{ backgroundImage: `url(${pb.baseUrl}/hero.jpg)` }} // Dynamically setting the background image
      >
        <div className="text-center">
          <h1 className="text-5xl font-bold mb-4">Discover Beautiful Jewelry</h1>
          <button
          onClick={() => scrollToSection('productList')}
          className="bg-indigo-600 text-white px-6 py-3 rounded-full text-lg cursor-pointer"
        >
          Shop Now
        </button>
        </div>
      </section>

      {/* Categories Section */}
      <section className="container mx-auto py-16">
        <h2 className="text-3xl font-bold text-center mb-8">Shop by Category</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <img src={`${pb.baseUrl}/rings.jpg`} className="w-full h-48 object-cover mb-4 rounded-lg" alt="Rings" />
            <h3 className="text-xl font-semibold">Rings</h3>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <img src={`${pb.baseUrl}/necklaces.jpg`} className="w-full h-48 object-cover mb-4 rounded-lg" alt="Necklaces" />
            <h3 className="text-xl font-semibold">Necklaces</h3>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <img src={`${pb.baseUrl}/bracelets.jpg`} className="w-full h-48 object-cover mb-4 rounded-lg" alt="Bracelets" />
            <h3 className="text-xl font-semibold">Bracelets</h3>
          </div>
        </div>
      </section>

      {/* Filter and Product Listing Section */}
      <section className="container mx-auto flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 py-16">
  {/* Filter Sidebar */}
  <aside className="w-full md:w-1/4 hidden md:block md:sticky md:top-16">
    <h2 className="text-xl font-semibold mb-4">Filter by</h2>
    <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
      <h3 className="font-semibold mb-2">Price Range</h3>
      <div className="mb-4">
        <label htmlFor="minPrice" className="block mb-1">Price from:</label>
        <input
          type="number"
          id="minPrice"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
          className="border rounded-md px-4 py-2 w-full"
        />
      </div>
      <div>
        <label htmlFor="maxPrice" className="block mb-1">Price to:</label>
        <input
          type="number"
          id="maxPrice"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
          className="border rounded-md px-4 py-2 w-full"
        />
      </div>
    </div>
    <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
      <h3 className="font-semibold mb-2">Category</h3>
      <select
        className="border rounded-md px-4 py-2 w-full"
        id="categoryFilter"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      >
        <option value="">All Categories</option>
        <option value="ring">Rings</option>
        <option value="necklace">Necklaces</option>
        <option value="bracelet">Bracelets</option>
      </select>
    </div>
  </aside>

  {/* Product Listing */}
  <div className="w-full">
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-2xl font-semibold">Products</h2>
      <select
        className="border rounded-md px-4 py-2"
        id="sortProducts"
        value={sortBy}
        onChange={(e) => setSortBy(e.target.value)}
      >
        <option value="-created">Sort by: Newest</option>
        <option value="price">Sort by: Price - Low to High</option>
        <option value="-price">Sort by: Price - High to Low</option>
      </select>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8" id="productList">
  {products.map((product) => (
    <div key={product.id} className="bg-white p-6 rounded-lg shadow-lg flex flex-col">
      <img
        src={getImageUrl(product)}
        className="w-full h-48 object-cover mb-4 rounded-lg"
        alt={product.name}
      />
      <h3 className="text-xl font-semibold">{product.name}</h3>
      <p className="text-gray-600">${product.price}</p>
      <div className="mt-auto flex justify-between mt-4">
        <button 
          onClick={() => handleAddToCart(product)} 
          className="bg-indigo-600 text-white px-4 py-2 rounded-full"
        >
          Add to Cart
        </button>
        <Link to={`/product/${product.id}`} className="text-indigo-600 hover:underline">
          View Details
        </Link>
      </div>
    </div>
  ))}
</div>

  </div>
</section>

    </Layout>
  );
};

export default Home;
