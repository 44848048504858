import React from 'react';
import Layout from './Layout'; // Import Layout komponentu

const Privacy = () => {
  return (
    <Layout>
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>
        <div className="text-lg text-gray-700 leading-relaxed">
          <p className="mb-4">Last Updated April 5, 2024</p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Your Guide to Privacy</h2>
          <h3 className="text-xl font-bold mt-4 mb-2">ALL ABOUT YOU</h3>
          <p className="mb-4">
            Your data will only be used to enhance your experience and fulfill our contract with you.
          </p>
          <h3 className="text-xl font-bold mt-4 mb-2">WE’VE GOT YOUR BACK</h3>
          <p className="mb-4">
            We’ll protect your data like it’s our own.
          </p>
          <h3 className="text-xl font-bold mt-4 mb-2">NO NONSENSE</h3>
          <p className="mb-4">
            We’ll communicate clearly in easy-to-understand language.
          </p>
          <h3 className="text-xl font-bold mt-4 mb-2">FULL CONTROL</h3>
          <p className="mb-4">
            Your data, your preferences—you control what and how you hear from us.
          </p>
          <h3 className="text-xl font-bold mt-4 mb-2">ONLY AS NEEDED</h3>
          <p className="mb-4">
            Your info won’t just sit around—if we don’t need it, we’ll delete it.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Details You Should Know</h2>
          <p className="mb-4">
            At Annabeth, we respect your right to privacy! It's important to us that you know how we collect and use your data to give you a better shopping experience.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">What Our Privacy Policy Covers</h2>
          <p className="mb-4">
            This Privacy Policy covers the personal data collected by Annabeth when you interact with our website, use our mobile app, or engage with us in other ways.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Information You Share With Us</h2>
          <p className="mb-4">
            Most of the information you provide to us happens when you're exploring our brand, like browsing our site, purchasing, or registering an account. In the past twelve (12) months, we’ve collected the following categories of personal data from our customers:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Identifiers such as your name, shipping address, phone number, IP address, and email address.</li>
            <li>Financial information as applicable to process transactions.</li>
            <li>Commercial information like records of your purchases at Annabeth.</li>
            <li>Technical data including IP addresses, browsing history, and device information.</li>
          </ul>
          <h2 className="text-2xl font-semibold mt-8 mb-4">How We Use Your Information</h2>
          <p className="mb-4">
            We collect only the information necessary to offer a relevant, personalized shopping experience. We may collect and process your personal data for the following purposes:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Processing orders, verifying identity, and handling payments.</li>
            <li>Communicating with you about your order status and updates.</li>
            <li>Marketing and personalization to offer products and promotions based on your preferences.</li>
            <li>Ensuring security, protecting against fraudulent or malicious activity, and prosecuting illegal actions.</li>
            <li>Debugging and identifying issues that impact our website.</li>
            <li>Compliance with applicable laws, regulations, and legal requests.</li>
            <li>Enforcing our Terms of Use.</li>
            <li>Protecting the rights and safety of our customers and third parties as necessary.</li>
          </ul>
          <p className="mb-4">
            We will not use your personal data for materially different or unrelated purposes without notifying you and obtaining consent when required.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">How We Obtain Your Personal Data</h2>
          <p className="mb-4">
            We collect personal data from the following sources:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Directly from you when you create or update an account.</li>
            <li>Automatically from you through cookies as you navigate our site.</li>
            <li>From our service providers.</li>
          </ul>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Legal Bases for Processing</h2>
          <p className="mb-4">
            We process personal data based on one or more of the following legal grounds:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Contractual Necessity. By using the site, you enter a contract with us through our Terms of Use, and certain personal data is required to fulfill that contract.</li>
            <li>Legitimate Interests. We process data for legitimate purposes, such as compliance, enforcing our Terms of Use, protecting rights, and detecting fraud.</li>
            <li>Compliance with Legal Obligations. We may process data to comply with legal requirements or to protect individuals using our site.</li>
          </ul>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Who We Share Your Information With</h2>
          <p className="mb-4">
            We share personal data with the following third parties:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Our service providers.</li>
            <li>Affiliated entities.</li>
            <li>Government or regulatory agencies as required by law or to protect the integrity of our site, interests, rights, or safety.</li>
          </ul>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Your Rights Regarding Personal Data</h2>
          <p className="mb-4">
            Depending on the laws applicable to you, you may have rights concerning your personal data, such as:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>The right to know why we process your data.</li>
            <li>The right to be informed about the data we collect.</li>
            <li>The right to learn the source of data we process.</li>
            <li>The right to access, modify, or correct your personal data.</li>
            <li>The right to know who we share your data with and for what purpose.</li>
            <li>The right to withdraw consent for processing where applicable.</li>
            <li>The right to file a complaint with a supervisory authority.</li>
          </ul>
          <p className="mb-4">
            To exercise these rights, please contact us at dataprotection@annabeth.store.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Accessing, Modifying, and Correcting Personal Data</h2>
          <p className="mb-4">
            We strive to keep your personal data accurate. You can update your information through your online account settings. Please review your information carefully to ensure it's up-to-date.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Your California Privacy Rights</h2>
          <p className="mb-4">
            California residents have specific rights under the "Shine the Light" law and CCPA, such as the right to request information on data shared for direct marketing. For requests, please contact dataprotection@annabeth.store.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">No Sale of Personal Data</h2>
          <p className="mb-4">
            We do not sell personal data as defined by the CCPA. For further information, California residents may contact us at dataprotection@annabeth.store.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Consumer Request by an Authorized Agent</h2>
          <p className="mb-4">
            If an authorized agent submits a request on your behalf, we may require verification of the agent's identity and authority. Contact dataprotection@annabeth.store to initiate a request.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">California Minor Content Removal</h2>
          <p className="mb-4">
            If you are a California resident under 18 and have posted content to our services, you may ask us to remove it by contacting dataprotection@annabeth.store.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Your European Union and UK Privacy Rights</h2>
          <p className="mb-4">
            Residents of the EU and UK have additional rights, such as:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>The right to object to automated decision-making.</li>
            <li>The right to request data portability in certain cases.</li>
            <li>The right to request deletion of data in certain circumstances.</li>
          </ul>
          <p className="mb-4">
            To exercise these rights, contact us at dataprotection@annabeth.store.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Data Retention</h2>
          <p className="mb-4">
            We retain your data as long as you have an account with us or as needed to provide services. Some data may be retained to meet legal requirements, prevent fraud, or resolve disputes.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Your Choices</h2>
          <p className="mb-4">
            You have control over how we collect and process certain information. You may opt out of communications or adjust your browser settings for cookies.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Communications Opt-Out</h2>
          <p className="mb-4">
            Unsubscribe from emails by clicking the link in the message or emailing dataprotection@annabeth.store with "Opt-Out" in the subject line.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Cookies and Web Tracking</h2>
          <p className="mb-4">
            See our Cookie Notice for more on managing cookies and web tracking preferences.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Legal Notice</h2>
          <p className="mb-4">
            All content on this site is protected by copyright and cannot be reproduced without Annabeth's permission. Orders may be canceled due to errors or for other reasons. If an order is canceled after a charge, we will issue a credit.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Changes to this Privacy Policy</h2>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. The latest changes will always be dated, and we may notify you of significant changes as required by law.
          </p>
          <p className="mb-4">
            If you have questions about your data protection, please contact our data protection officer (DPO) at dataprotection@annabeth.store.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">Messaging</h2>
          <p className="mb-4">
            By using our services, you also agree to our Messaging Terms and Messaging Privacy Policy.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
