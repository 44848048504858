import React, { useState } from 'react';
import Layout from './Layout'; // Import Layout komponentu

const Checkout = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Zatiaľ iba vizuálne, neskôr tu napojíme Stripe alebo iný platobný systém
    console.log({
      name,
      email,
      address,
      cardNumber,
      expiryDate,
      cvv,
    });
  };

  return (
    <Layout>
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold mb-6">Checkout</h1>
        <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md max-w-lg mx-auto">
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="w-full border rounded-md px-4 py-2"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full border rounded-md px-4 py-2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="address" className="block text-gray-700 font-semibold mb-2">
              Shipping Address
            </label>
            <input
              type="text"
              id="address"
              className="w-full border rounded-md px-4 py-2"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="cardNumber" className="block text-gray-700 font-semibold mb-2">
              Card Number
            </label>
            <input
              type="text"
              id="cardNumber"
              className="w-full border rounded-md px-4 py-2"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              required
              maxLength={16}
            />
          </div>
          <div className="flex space-x-4 mb-4">
            <div className="w-1/2">
              <label htmlFor="expiryDate" className="block text-gray-700 font-semibold mb-2">
                Expiry Date (MM/YY)
              </label>
              <input
                type="text"
                id="expiryDate"
                className="w-full border rounded-md px-4 py-2"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                required
                maxLength={5}
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="cvv" className="block text-gray-700 font-semibold mb-2">
                CVV
              </label>
              <input
                type="text"
                id="cvv"
                className="w-full border rounded-md px-4 py-2"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                required
                maxLength={3}
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-3 rounded-md text-lg font-semibold hover:bg-indigo-700"
          >
            Confirm Payment
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default Checkout;
