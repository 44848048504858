import React from 'react';
import Layout from './Layout'; // Import Layout komponentu

const About = () => {
  return (
    <Layout>
      <div className="container mx-auto py-12 flex flex-col md:flex-row items-center">
        <div className="text-lg text-gray-700 leading-relaxed md:w-2/3 md:pr-8">
          <h1 className="text-4xl font-bold mb-6">About Us</h1>
          <p className="mb-4">
            Welcome to Annabeth Jewelry, your number one source for unique and exquisite jewelry. We are dedicated to providing you with the finest pieces of jewelry, focusing on quality, craftsmanship, and elegance.
          </p>
          <p className="mb-4">
            At Annabeth, we believe that jewelry is more than just an accessory—it’s a symbol of love, celebration, and personal style. Whether you are searching for a timeless piece to commemorate a special moment or a statement accessory for everyday wear, our collection has something for everyone.
          </p>
          <p className="mb-4">
            Our journey started with a passion for design and the desire to make high-quality jewelry accessible to everyone. Over the years, we have grown into a trusted brand known for our curated selection of rings, necklaces, bracelets, and earrings. Every piece in our collection is hand-selected to ensure it meets our standards of beauty and durability.
          </p>
          <p className="mb-4">
            We are proud to offer a wide range of jewelry, crafted from the finest materials and created by skilled artisans. From elegant and simple designs to bold and intricate pieces, each item tells a story and adds a touch of luxury to any outfit.
          </p>
          <p className="mb-4">
            Our mission is to help you find jewelry that makes you feel beautiful and confident. Whether you are gifting a loved one or treating yourself, we are here to ensure that your experience with us is exceptional from start to finish.
          </p>
          <p className="mb-4">
            Thank you for choosing Annabeth Jewelry. We are excited to be a part of your journey and help you celebrate life’s most precious moments.
          </p>
          <p className="mt-6">
            <strong>With love,</strong>
            <br />
            The Annabeth Team
          </p>
        </div>
        <div className="md:w-1/3 mt-6 md:mt-0">
          <img 
            src={`${process.env.PUBLIC_URL}/images/about.jpg`} 
            alt="Annabeth Storefront" 
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </Layout>
  );
};

export default About;
