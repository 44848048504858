import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './Cart';

// Funkcia na ukladanie stavu do localStorage
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('cart', serializedState);
  } catch (e) {
    console.error("Could not save state", e);
  }
};

// Funkcia na načítanie stavu z localStorage
const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('cart');
    if (serializedState === null) return undefined; // Ak nie je uložený stav, vráti default hodnotu
    return JSON.parse(serializedState);
  } catch (e) {
    console.error("Could not load state", e);
    return undefined;
  }
};

// Načítanie stavu košíka z localStorage
const persistedState = loadFromLocalStorage();

const store = configureStore({
  reducer: {
    cart: cartReducer,
  },
  preloadedState: { cart: persistedState }, // Inicializujeme store s hodnotami z localStorage
});

// Uloženie stavu po každej zmene
store.subscribe(() => {
  saveToLocalStorage(store.getState().cart);
});

export default store;
