import React from "react";
import { useParams } from "react-router-dom";
import articles from "../data/Articles";
import Layout from "./Layout";

const Article = () => {
  const { id } = useParams();
  const article = articles.find((item) => item.id === parseInt(id));

  if (!article) {
    return (
      <Layout>
        <div className="container mx-auto py-20 text-center">
          <h2 className="text-3xl font-bold text-gray-800">Article Not Found</h2>
          <p className="text-gray-600">The article you're looking for does not exist.</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto py-20 px-4 md:px-8">
        <div className="mb-12 text-center">
          <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">{article.title}</h1>
          <p className="text-gray-500 text-sm md:text-base">{article.date} by {article.author}</p>
        </div>
        
        <div className="mb-12 rounded-lg overflow-hidden shadow-lg">
          <img 
            src={article.image} 
            alt={article.title} 
            className="w-full h-80 object-cover"
          />
        </div>
        
        <div className="max-w-3xl mx-auto text-lg text-gray-700 leading-relaxed space-y-6">
          {article.content.split("\n").map((paragraph, index) => (
            <p 
              key={index} 
              className={`first-letter:text-4xl first-letter:font-bold first-letter:text-indigo-600 ${
                paragraph.trim().startsWith("-") ? "ml-4 pl-4 border-l-4 border-indigo-600" : ""
              }`}
            >
              {/* Render odrážky ako tučný text pre lepšie formátovanie */}
              {paragraph.trim().startsWith("-") ? (
                <span className="font-semibold text-gray-800">{paragraph.replace("-", "").trim()}</span>
              ) : (
                paragraph
              )}
            </p>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Article;
