import React from 'react';
import Layout from './Layout'; // Import Layout komponentu

const Terms = () => {
  return (
    <Layout>
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold mb-6">Terms of Use</h1>
        <div className="text-lg text-gray-700 leading-relaxed">
          <p className="mb-4">
            These Terms of Use (“Terms”) contain an arbitration provision and class action waiver that apply to all claims brought against Annabeth in the United States. Please review the Arbitration Agreement section for details.
          </p>
          <p className="mb-4">
            Annabeth, inc. ("Annabeth", “we”, “our”, “us”), as a convenience to you, grants you (“you”, “your”) access to its websites, accessible via www.annabeth.store or any website owned or controlled by Annabeth, including its subdomains (collectively, the “Website”), and use of its applications (hereinafter referred to as the “Apps”) (together the “Site”), conditioned on your acceptance of these Terms, conditions, and notices contained herein (the “Terms”) (together with, our Privacy Policy, Return Policies, and, if applicable, the Cookie Policy, and any other documents referred to herein).
          </p>
          <p className="mb-4">
            BY USING OUR SITE, YOU ARE AGREEING TO THE TERMS BELOW AS WELL AS THE PRIVACY POLICY, AS THEY MAY BE UPDATED AND AMENDED FROM TIME TO TIME. IF YOU DO NOT AGREE TO THE TERMS OR THE PRIVACY POLICY, DO NOT USE THE SITE.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">1. User Generated Content</h2>
          <p className="mb-4">
            For the purposes of these Terms, "User Generated Content" means collectively any text, images, graphics, software, source code, Apps, specifications, audio files, videos, articles, trademarks, logos, and other information or content made available through the Site that are not Unsolicited Submission (as that term is defined in Section 7 below), including but not limited to the design, structure, selection, coordination, expression, "look and feel", and arrangement of such content.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">2. Privacy Policy</h2>
          <p className="mb-4">
            We respect your right to privacy at Annabeth! It's important to us that you know how we collect and use your personal information, and that you know that your personal information will only be used in compliance with our Privacy Policy, which is incorporated into these Terms by this reference. For further information regarding AnnaBeth’s processing of your personal information, please refer to our Privacy Policy, available at https://www.annabeth.store/pages/privacy-policy.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">3. Change and Supplement to These Terms</h2>
          <p className="mb-4">
            These Terms may be revised at our sole discretion at any time and from time to time by updating them here. You are expected to check this page from time to time to take notice of any changes Annabeth has made, as they are binding on you. The Terms were updated as of March 13, 2024, and will be in effect no later than April 24, 2024, solely to the extent you live in a jurisdiction that requires advance notice. When changes are made, we will notify you by making the revised version available on this webpage or by providing written notice to you by email and will indicate at the top of this page the date that revisions were last made. You understand and agree that your continued access to or use of the Site after any posted modification to these Terms indicates your acceptance of the modifications. If you do not agree with the modified Terms, you should stop using the Site.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">4. Authorized Use</h2>
          <p className="mb-4">
            The Site may be accessed and used only by individuals who can form legally binding contracts under applicable laws, who are of legal age of majority in their place of residence, for example, 18 years of age or older, and who are not otherwise barred from using the Platform under applicable laws.
          </p>
          <p className="mb-4">
            The Site is provided solely as a convenience to you for non-commercial use. You may access and use the Site only in accordance with all applicable laws and regulations and with these Terms.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">5. User Account</h2>
          <p className="mb-4">
            If you purchase products or services using the Site, you may be prompted to establish an account with Annabeth, and if you wish, you can choose to do so. You are solely responsible for (i) ensuring the security and confidentiality of your account and your account password; (ii) restricting access to your computer and your account; and (iii) maintaining the current email address listed on your account. You are solely accountable and liable for all activities, including, without limitation, all purchases of products and services through the use of the Platform that occur under your account or your account password.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">6. Intellectual Property Rights</h2>
          <p className="mb-4">
            All Content and aspects of the Site, as well as the selection and arrangement thereof, including, without limitation, any works of authorship (e.g., any trailers, images, sounds, etc.), trademarks, trade dress, features, or functionality, and other intellectual property, and any merchandise information, product descriptions, reviews, comments, communications, feedback, and other information, data, and materials (collectively, “Materials”) that Annabeth makes available on or through the Site are owned or controlled by or licensed to Annabeth, its licensors, or other providers of such Materials and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">7. User Generated Content</h2>
          <p className="mb-4">
            The Site may provide you with the ability to create, post, upload, display, publish, transmit, broadcast, share, or otherwise make available content, including messages, text, illustrations, files, images, graphics, photographs, ratings, reviews, questions, products, listings, descriptions and images of goods or services, resources or other information or materials (together, “User Generated Content”).
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">8. Limitation of Liability</h2>
          <p className="mb-4">
            In no event shall Annabeth Jewelry, its directors, employees, affiliates, agents, contractors, interns, suppliers, or licensors be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, revenue, data, or other intangible losses arising out of or related to your use of the website or products. This limitation of liability applies even if we have been advised of the possibility of such damages.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">9. Dispute Resolution and Arbitration</h2>
          <p className="mb-4">
            PLEASE READ THIS SECTION CAREFULLY. IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">10. California Prop 65</h2>
          <p className="mb-4">
            WARNING: Products sold on this site may contain chemicals known to the State of California to cause cancer, birth defects or other reproductive harm.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">11. Electronic Communications</h2>
          <p className="mb-4">
            When you create an Account with us and provide your email address and/or mobile phone number and/or when you communicate with us electronically, such as via email or through the Site, YOU EXPRESSLY CONSENT TO RECEIVING COMMUNICATIONS ELECTRONICALLY, POSTED ON THE SITE, AND/OR VIA TEXT MESSAGE, CALLS, AND PUSH NOTIFICATIONS TO YOUR PHONE FROM US.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">12. Applicable Law</h2>
          <p className="mb-4">
            These Terms shall be construed in accordance with the laws of the State of California, without regard to any conflict of law provisions.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">13. Entire Agreement; Waiver and Survival</h2>
          <p className="mb-4">
            These Terms govern our relationship with you as it relates to the Site. You confirm that, in agreeing to accept the Terms, you have not relied on any representation except as expressly set forth in these Terms and you agree that you shall have no remedy in respect of any such representation not included in these Terms.
          </p>

          <p className="mb-4">
            If you have any questions about these terms, please contact us at support@annabethjewelry.com. Your satisfaction is our priority, and we strive to ensure a transparent and pleasant shopping experience.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
