import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addItemToCart, removeItemFromCart } from '../store/Cart'; // Import akcií pre pridanie a odstránenie položiek
import { Link } from 'react-router-dom';
import Layout from './Layout'; // Import Layout komponentu
import PocketBase from 'pocketbase'; // Import PocketBase

const pb = new PocketBase('https://annabeth.store'); // Inicializácia PocketBase

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.items); // Získať položky z Redux store
  const totalPrice = useSelector((state) => state.cart.totalPrice); // Celková cena z Redux store
  const dispatch = useDispatch(); // Definovanie dispatchu pre Redux

  const [productImages, setProductImages] = useState({}); // Uloženie obrázkov pre produkty

  // Funkcia na získanie URL obrázku produktu, podobne ako v Home.js
  const getImageUrl = (product) => {
    if (product.images && product.images.length > 0) {
      return pb.files.getUrl(product, product.images[0], { thumb: '300x300' });
    }
    return 'https://via.placeholder.com/150'; // Náhradný obrázok, ak produkt nemá obrázok
  };

  useEffect(() => {
    const fetchProductImages = async () => {
      const images = {};
      for (const item of cartItems) {
        try {
          const product = await pb.collection('products').getOne(item.id); // Načítanie produktu podľa ID
          images[item.id] = getImageUrl(product); // Získanie URL prvého obrázku
        } catch (error) {
          console.error('Error fetching product image:', error);
          images[item.id] = 'https://via.placeholder.com/150'; // Náhradný obrázok v prípade chyby
        }
      }
      setProductImages(images); // Nastavenie obrázkov
    };

    fetchProductImages();
  }, [cartItems]);

  const handleRemoveItem = (item) => {
    dispatch(removeItemFromCart(item.id)); // Odstránenie položky z košíka
  };

  const handleAddItem = (item) => {
    dispatch(addItemToCart(item)); // Pridanie položky do košíka
  };

  return (
    <Layout>
      <div className="container mx-auto py-8">
        <h2 className="text-3xl font-semibold mb-6">Your Cart</h2>
        {cartItems.length === 0 ? (
          <p className="text-gray-600">Your cart is empty. <Link to="/" className="text-indigo-600">Go back shopping!</Link></p>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {cartItems.map((item) => (
                <div key={item.id} className="flex items-center bg-white p-4 rounded-lg shadow-md">
                  <div className="w-1/4">
                    <img src={productImages[item.id] || 'https://via.placeholder.com/150'} alt={item.name} className="rounded-lg" />
                  </div>
                  <div className="w-3/4 pl-4">
                    <h3 className="text-lg font-semibold">{item.name}</h3>
                    <p className="text-gray-600">${item.totalPrice.toFixed(2)}</p>
                    <div className="flex items-center mt-4 space-x-2">
                      <button
                        onClick={() => handleAddItem(item)}
                        className="px-3 py-1 bg-green-500 text-white rounded-full hover:bg-green-600"
                      >
                        +
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        onClick={() => handleRemoveItem(item)}
                        className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-6 flex justify-between items-center">
              <h3 className="text-2xl font-semibold">Total: ${totalPrice.toFixed(2)}</h3>
              <Link to="/checkout" className="bg-indigo-600 text-white px-6 py-2 rounded-full hover:bg-indigo-700">
                Proceed to Checkout
              </Link>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Cart;
