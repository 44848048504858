import React from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import articles from "../data/Articles";

const Blog = () => {
  return (
    <Layout>
      <div className="container mx-auto py-20">
        <h1 className="text-5xl font-extrabold text-center mb-12 text-gray-800">AnnaBeth Blog</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {articles.map((article) => (
            <div key={article.id} className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:-translate-y-2 transition duration-300">
              <img src={article.image} alt={article.title} className="w-full h-64 object-cover" />
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">{article.title}</h2>
                <p className="text-sm text-gray-500 mb-4">{article.date} by {article.author}</p>
                <p className="text-gray-700 mb-6">{article.summary}</p>
                <Link to={`/article/${article.id}`} className="text-indigo-600 font-semibold hover:underline">
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
